'use strict';

import Loading from '../components/Loading';

class SideBeardWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            isOpened: false,
            hasCompany: false,
            type: 'requests',
            tab: 'current',
            filter: 'all',

            client: {
                items: [],
            },
            broker: {
                items: [],
            },
            noty: {
                new: 0,
                items: []
            },
            filterGroups: [],
            emptyItems: [],

            initiallyLoaded: false,
        }
        this.ref = React.createRef(null);
        this.renderItem = this.renderItem.bind(this)
    }

    componentDidMount() {
        storageListen('userToken', token => {
            if(token !== null) {
                this.init();
            } else {
                this.setOpened(false);
            }
        })
        this.init();

        listenEvent('toggleSideBeard', data => {
            let isOpened = !this.state.isOpened;
            if(data) {
                if(data.state) this.setState(data.state);
                if(data.state || data.fetch) this.fetchData();
                if(typeof data.isOpened !== "undefined") {
                    isOpened = data.isOpened
                }
            }
            this.setOpened(isOpened);
        })
    }


    init() {
        const that = this;

        const userWidgetData = storageGet('userWidgetData');
       if(userWidgetData) {
           this.setState({hasCompany: userWidgetData.hasCompany})
       } else {
           storageListen ( 'userWidgetData', ( data ) => this.setState ( { hasCompany: (data && data.hasCompany) || false } ) );
       }

        function setSelectState (state) {
            if(!state.id) return state.text;
            return $('<span>' + $(state.element).attr('data-text') + '</span> <sup>' + $(state.element).attr('data-count') + '</sup>');
        }

        $('#bagSelect').select2({
            minimumResultsForSearch: Infinity,
            templateResult: setSelectState,
            templateSelection: setSelectState
        }).on("change", function() {
            that.setState({filter: $(this).val()})
        })
            .on('select2:open', function () {
                $('.select2-container--open').css({zIndex: 11})
            }); // Другие варианты не помогли (css, dropdownParent)

        $(document).on('click', function(e){
            if (!$(e.target).closest('.push-wrapp, .logined-link').length) {
                if(that.state.isOpened) {
                    raiseEvent('toggleSideBeard');
                }
            }
        });

        new SimpleBar(this.ref.current.closest('.push-bag'));

        this.fetchData();
    }

    setOpened(isOpened) {
        this.setState({isOpened}, () => {
            if(isOpened) {
                $('#reactSideBeard').addClass('opened')
                $(".page").addClass('blured')
                $("body").css("overflow", "hidden")
            } else {
                $('#reactSideBeard').removeClass('opened')
                $(".page").removeClass('blured')
                $("body").css("overflow", "auto")
            }
        })
    }

    fetchData(force = false) {
        if(!force && this.state.isFetching) return;
        this.setState({isFetching: true});
        accountAction.initSideBeard()
            .then(response => {
                let defaultType = this.state.type;
                if (!this.state.initiallyLoaded) {
                    const firstNoty = response.noty.items[0];
                    if (firstNoty) {
                        const clientItem = response.client.items.find(item =>
                            item.request_number === firstNoty.request_number &&
                            item.code === 'confirmed' &&
                            !item.inArchive
                        );
                        if (clientItem)
                        {
                            defaultType = 'flights';
                        }
                    }
                }
                this.setState({
                    isFetching: false,
                    initiallyLoaded: true,
                    ...response,
                    type: defaultType,
                    noty: {
                        new: response.noty.new,
                        items: response.noty.items
                    }
                });
            })
            .catch(() => {
                this.setState({ isFetching: false })
            })
    }

    handleAction(e, item, action) {
        e.preventDefault();

        if(action.slice(0, 1) === '/') window.location = action;
        if(action === 'view') window.location = `/flights/${item.id}`;
        if(action === 'data') window.location = `/flights/data/${item.id}`;

        if(action === 'delete') {
            this.setState({isFetching: true});
            requestAction.deleteRequest(item.id).then(response => {
                if(response.status === 'success') {
                    this.fetchData(true);
                } else {
                    this.setState({isFetching: false});
                }
            })
        }
    }

    getEmptyItems() {
        const {emptyItems} = this.state;
        return emptyItems;
    }

    getItems() {
        const {type, client, broker, noty} = this.state;
        let items = client.items;
        // if(type === 'flights') items = broker.items;
        if(type === 'noty') items = noty.items;
        return items;
    }

    getPreFilteredItems() {
        const {tab, type} = this.state;
        const items = this.getItems();

        if(type === 'noty') return items;

        let filtered_items = [];
        const is_archive_tab = tab === 'archive'

        if (type === 'requests')
            filtered_items = items.filter(item => item.code === 'new');
        if (type === 'flights')
            filtered_items = items.filter(item => item.code === 'confirmed');

        filtered_items = filtered_items.filter(item => item.inArchive === is_archive_tab);

        return filtered_items;
    }

    getFilteredItems() {
        const {filter, filterGroups} = this.state;
        let items = this.getPreFilteredItems();
        if (filter !== 'all') {
            const filterGroup = filterGroups.find(e => e.key === filter);
            if (filterGroup) {
                const types = filterGroup.types_short;
                items = items.filter(item => types.includes(item.type));
            }
        }
        return items;
    }

    getCurrentItemsCount() {
        const {type} = this.state;
        const code = type === 'flights' ? 'confirmed' : 'new';

        return this.getItems()
            .filter(item => !item.inArchive)
            .filter(item => item.code === code)
            .length;
    }

    getArchiveItemsCount() {
        const {type} = this.state;
        const code = type === 'flights' ? 'confirmed' : 'new';

        return this.getItems()
            .filter(item => item.inArchive)
            .filter(item => item.code === code)
            .length;
    }

    handleChangeType(e, type) {
        e.preventDefault()
        if(this.state.type === type) return;
        if(type === 'noty' && this.state.noty.new > 0) accountAction.notySetLastViewed().then(() => {
            storageSet('userWidgetData', {count: 0});
            this.setState({
                noty: {
                    ...this.state.noty,
                    new: 0,
                }
            })
        })
        this.setState({type})
        this.resetFilter()
    }

    handleChangeTab(e, tab) {
        e.preventDefault()
        this.setState({tab})
        this.resetFilter()
    }

    resetFilter() {
        // Иначе фильтр может зависнуть на том, которого нет у других заявок, выводится пустота
        this.setState({filter: 'all'})
    }

    renderItem(item, index) {
        const {isFetching} = this.state;
        const mainKey = `sideBeardItem${index}`;

        if(item.isEmpty) return <div key={mainKey} className="push-item push-item__empty">
            <h3>{item.title}</h3>
            {item.text && <p>{item.text}</p>}
        </div>

        let titleHtml = item.title.text,
            linkHref = null,
            linkAction = null;

        if(item.title.link) {
            linkHref = item.title.link.url ? item.title.link.url : '#';
            linkAction = item.title.link.action ? e => this.handleAction(e, item, item.title.link.action) : null;
            // const link = <a href={linkHref} onClick={linkAction}>{item.title.link.text}</a>;
            const link = <span className={'not_link'}>{item.title.link.text}</span>;
            titleHtml = titleHtml.split(item.title.link.replace);
            if(titleHtml.length > 0) titleHtml.splice(1, 0, link);
            titleHtml = <React.Fragment>
                {titleHtml[0]}{titleHtml[1]}{titleHtml[2]}{titleHtml[4]}
            </React.Fragment>
        }

        const linkItemBlock = () => {
            return <div>
                <div className="push-item__top">
                    {!!item.request_number && <div className="push-item__status-count">
                        {tMsg('Заявка')} <span>№{item.request_number}</span>
                    </div>}
                    {!!item.date && <div className="push-item__date">
                        {item.date}
                    </div>}
                </div>
                <div className="push-item__status">
                    <span>{titleHtml}</span>
                    {!!item.title.icon && <i className={`icon-${item.title.icon}`}/>}
                </div>
                {!!item.routes && item.routes.map((route, index) => {
                    const key = `${mainKey}Route${index}`;
                    return <div key={key}
                                className={clsx({'push-item__border': !!item.price || item.routes.length > 1})}>
                        <div className="push-item__direction">
                            {!!route.from.icao && <div><span>{route.from.icao}</span> {route.from.title}</div>}
                            {!!route.to.icao && <div><span>{route.to.icao}</span> {route.to.title}</div>}
                        </div>
                        <div className="push-item__row">
                            <div className="push-item__info">
                                <span>{tMsg('Тип запроса')}</span>
                                {route.type}
                            </div>
                            {!!route.date && <div className="push-item__info">
                                <span>{tMsg('Вылет')}</span>
                                {route.date}
                                {!!route.time && ` (${route.time})`}
                            </div>}
                            {!!route.arriveDate && <div className="push-item__info">
                                <span>{tMsg('Прилет')}</span>
                                {route.arriveDate}
                                {!!route.arriveTime && ` (${route.arriveTime})`}
                            </div>}
                            {route.passengers > 0 && <div className="push-item__info">
                                <span>{tMsg('Пассажиры')}</span>
                                {route.passengers}
                            </div>}
                        </div>
                    </div>
                })}
            </div>
        }

        return <div key={mainKey} className="push-item">
            {React.createElement((linkHref || linkAction) ? 'a' : 'div', (linkHref || linkAction) ? {
                href: linkHref,
                onClick: linkAction,
            } : {}, linkItemBlock())}

            {!!item.price && <div className="push-item__price">{item.price}</div>}
            {!!item.text && <div className="push-item__text">{item.text}</div>}
            {!!item.buttons && <div className="push-item__btns">
                {item.buttons.map((button, index) => {
                    const key = `${mainKey}Button${index}`;
                    const type = button.type === 'red' ? 'base' : button.type;
                    return <a key={key}
                        className={`button button-${type}`}
                        onClick={e => this.handleAction(e, item, button.action)}
                    >
                        {button.text}
                    </a>
                })}
            </div>}
            <Loading active={isFetching}/>
        </div>
    }

    renderFilter() {
        const {filter, filterGroups} = this.state;
        const items = this.getPreFilteredItems();

        let filterItems = [{key: 'all', title: tMsg('Все'), types: [], count: 0}]
        filterGroups.forEach(({key, title, types_short}) => {
            filterItems.push({key: key, title: title, types: types_short, count: 0});
        });

        items.map(item => {
            const filterItem = filterItems.find(e => e.types.includes(item.type))
            const allFilterItem = filterItems.find(e => e.key === 'all');

            if (filterItem) {
                allFilterItem.count++;
                filterItem.count++
            }
        });

        const filterGroupAll = filterItems.find(e => e.key === 'all');
        const filterGroupAllCount = filterGroupAll ? filterGroupAll.count : 0;

        return <div className="bag-select" style={filterGroupAllCount === 0 ? {display: 'none'} : {}}>
            <select id="bagSelect" value={filter} onChange={() => {}}>
                {filterItems.map(({key, title, count}) => count > 0 && <option
                    key={`sideBeardFilter_${key}_${count}`}
                    value={key}
                    data-text={title}
                    data-count={count}
                />)}
            </select>
        </div>
    }

    render() {
        const {isFetching, hasCompany, broker, noty, type, tab} = this.state;

        const filteredItems = this.getFilteredItems();

        const hasItems = filteredItems.length > 0

        return <div ref={this.ref} className="push-scroll">
            <div className="push-wrapp">
                <div className="push-bag__top">
                    <div className="push-bag__head">
                        <ul className="bag-menu">
                            <li><a href="/profile">{tMsg('Профиль')}</a></li>
                            {hasCompany && <li><a href="/profile/company">{tMsg('Компания')}</a></li>}
                            <li><a href='#' onClick={e => authAction.logout(e)}>{tMsg('Выйти')}</a></li>
                        </ul>
                        <a href='#' className={`bag-notice status-${noty.new > 0 || type === 'noty' ? 'three' : 'two'}`} onClick={e => this.handleChangeType(e, 'noty')}>
                            {noty.new > 0 && <span>{noty.new}</span>}
                            <i className="icon-bell"/>
                        </a>
                    </div>
                    <div className="bag-swither">
                        <a href='#' className={type === 'requests' ? 'active' : ''} onClick={e => this.handleChangeType(e, 'requests')}>
                            <i className="icon-list"/>{tMsg('Мои заявки')}
                        </a>
                        <a href='#' className={type === 'flights' ? 'active' : ''} onClick={e => this.handleChangeType(e, 'flights')}>
                            <i className="icon-plane1"/>{tMsg('Мои полёты')}
                        </a>
                    </div>
                    {type !== 'noty' && <div className="bag-tabs">
                        <a href='#' className={tab === 'current' ? 'active' : ''} onClick={e => this.handleChangeTab(e, 'current')}>
                            {tMsg('Текущие')} <sup>{this.getCurrentItemsCount()}</sup>
                        </a>
                        <a href='#' className={tab === 'archive' ? 'active' : ''} onClick={e => this.handleChangeTab(e, 'archive')}>
                            {tMsg('Архив')} <sup>{this.getArchiveItemsCount()}</sup>
                        </a>
                    </div>}
                    {this.renderFilter()}
                    <Loading active={isFetching}/>
                </div>

                {hasItems && filteredItems.map(this.renderItem)}

                {!hasItems && this.getEmptyItems().map(this.renderItem)}

            </div>
        </div>
    }
}

const domContainer = document.querySelector('#reactSideBeard');
const root = ReactDOM.createRoot(domContainer);
root.render(React.createElement(SideBeardWidget));
